import React from 'react'
import styled from "styled-components"
import { Link } from 'gatsby'
import { getGridSpans, mq } from '@stylesheets/settings'

import Layout from '../components/layout'
import { IconClose } from '../components/elements/icon'
import MixedCopy from '../components/elements/mixedcopy'
import SEO from '../components/seo'
import ImprintContent from '../static/data/impressum.html'

const Wrapper = styled.div`
  display: flex;
  ${getGridSpans('width', { s:4, m: [4, 'full'], l: 6 })};
  margin: 0 auto;
  padding: 50px 0;
`;

const CloseButton = styled(Link)`
  position: absolute; 
  right: 20px; 
  top: 20px; 
  width: 40px;
  height: 40px;
  alignSelf:flex-end;
`;

const ImprintPage = () => (
  <Layout>
    <SEO title="Imprint" />
    <CloseButton to="/"><IconClose fill={'black'} width={'25px'} height={'25px'} /></CloseButton>
    <Wrapper>
      <MixedCopy html={ImprintContent}></MixedCopy>
    </Wrapper>
  </Layout>
)

export default ImprintPage
